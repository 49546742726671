<template>
  <div class="row justify-content-center align-items-center m-5 py-5">
    <div class="col-sm-12">
      <div class="section-title mt-5">
        <h2 class="text-center">{{ $t("header.calculators") }}</h2>
        <div class="bar"></div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-6">
      <router-link to="" v-scroll-to="'#swap'">
        <div class="row justify-content-center align-items-center my-3">
          <div class="col-6 col-sm-3 col-md-2">
            <img
              src="../../../assets/img/calculators/swap-calculator-icon.png"
              alt="swap calculator"
            />
          </div>
          <div class="col-sm-9 col-md-8">
            <h5>{{ $t("calculators.subtitle2-1") }}</h5>
            <p>{{ $t("calculator-summaries.swap") }}</p>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-sm-12 col-lg-6">
      <router-link to="" v-scroll-to="'#cash'">
        <div class="row justify-content-center align-items-center my-3">
          <div class="col-6 col-sm-3 col-md-2">
            <img
              src="../../../assets/img/calculators/cash-calculator-icon.png"
              alt="cash calculator"
            />
          </div>
          <div class="col-sm-9 col-md-8">
            <h5>{{ $t("calculator-landing.title") }}</h5>
            <p>{{ $t("calculator-summaries.cash") }}</p>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-sm-12 col-lg-6">
      <router-link to="" v-scroll-to="'#mixes'">
        <div class="row justify-content-center align-items-center my-3">
          <div class="col-6 col-sm-3 col-md-2">
            <img
              src="../../../assets/img/calculators/mixes-calculator-icon.png"
              alt="mixes calculator"
            />
          </div>
          <div class="col-sm-9 col-md-8">
            <h5>{{ $t("calculator-mixes.title") }}</h5>
            <p>{{ $t("calculator-summaries.mixes") }}</p>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-sm-12 col-lg-6">
      <router-link to="" v-scroll-to="'#paypal'">
        <div class="row justify-content-center align-items-center my-3">
          <div class="col-6 col-sm-3 col-md-2">
            <img
              src="../../../assets/img/calculators/paypal-calculator-icon.png"
              alt="paypal calculator"
            />
          </div>
          <div class="col-sm-9 col-md-8">
            <h5>{{ $t("calculators.title1") }}</h5>
            <p>{{ $t("calculator-summaries.paypal") }}</p>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-sm-12">
      <hr class="solid" />
    </div>
    <SwapCalculator v-if="rateSwap" :rates="rateSwap" id="swap" />
    <div class="col-sm-12">
      <hr class="solid" />
    </div>
    <div class="row justify-content-center align-items-center mx-3" id="cash">
      <div class="col-lg-6 col-md-8 col-sm-12 mb-4">
        <div class="page-title-area">
          <div class="section-title mb-0">
            <h2 class="text-center">{{ $t("calculator-landing.title") }}</h2>
            <div class="bar"></div>
          </div>
        </div>
        <div class="calculator-container">
          <LandingCalculator :rateData="rateData" :isOnLanding="false" />
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <hr class="solid" />
    </div>
    <div class="row justify-content-center align-items-center mb-3" id="mixes">
      <div class="col-sm-12 col-lg-8">
        <div class="page-title-area pb-0">
          <div class="container">
            <div class="section-title mb-0">
              <h2 class="text-center">{{ $t("calculator-mixes.title") }}</h2>
              <div class="bar"></div>
            </div>
          </div>
        </div>
        <div class="row justify-content-start align-items-center ml-3">
          <b-form-group
            :label="$t('calculator-mixes.toggle')"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              v-model="mixesCalculatorMode"
              :options="mixesCalculatorOptions"
              :aria-describedby="ariaDescribedby"
              value-field="item"
              text-field="name"
            />
          </b-form-group>
        </div>
      </div>
      <div class="col-sm-12 col-lg-8">
        <MixesReceiveCalculator
          v-if="mixesCalculatorMode === 'receive'"
          :optionsSelected="optionsSelected"
          :comisionData="comision"
        />
        <MixesSendCalculator
          v-else
          :optionsSelected="optionsSelected"
          :comisionData="comision"
        />
      </div>
    </div>
    <div class="col-sm-12">
      <hr class="solid" />
    </div>
    <PaypalCalculator v-if="ratePaypal" :rates="ratePaypal" id="paypal" />
    <!-- <WarningModalWhatsApp></WarningModalWhatsApp> -->
  </div>
</template>

<script>
import Dinero from "dinero.js";
import { mapMutations } from "vuex";
import LandingCalculator from "../../landing/landing-sections/Calculator";
import PaypalCalculator from "./components/Paypal";
import SwapCalculator from "./components/Swap";
import MixesReceiveCalculator from "./components/mixes";
import MixesSendCalculator from "./components/mixesEnv";
import { getRates } from "../../../constants/config";
// import WarningModalWhatsApp from "../../other-pages/common/WarningModalWhatsApp";

export default {
  name: "Calculators",
  components: {
    LandingCalculator,
    PaypalCalculator,
    SwapCalculator,
    MixesReceiveCalculator,
    MixesSendCalculator,
    // WarningModalWhatsApp,
  },
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      ratePaypal: "",
      rateSwap: "",
      rateData: {},
      optionsSelected: [],
      comision: {},
      mixesCalculatorOptions: [
        { item: "receive", name: "Yo quiero recibir" },
        { item: "send", name: "Yo envío" },
      ],
      mixesCalculatorMode: "receive",
    };
  },
  async created() {
    window.addEventListener("resize", this.onResize);
    const data = await this.getRates();
    const { rate, rateUSD, divisasData, client, usd, eur, paypal, comision } =
      data;
    this.ratePaypal = { rate, rateUSD, client, comision };
    this.rateSwap = { rate, rateUSD, divisasData, client, comision };
    this.rateData = data;
    this.comision = comision;

    const divisasKeys = Object.keys(divisasData).reduce((acc, ele) => {
      if (ele !== "usdt") {
        acc.push(this.toUpperCase(ele));
      } else {
        acc.push(ele.toUpperCase());
      }
      return acc;
    }, []);

    this.optionsSelected = [...divisasKeys];

    divisasKeys.push("Bolivares");

    this.setArrayOperations([
      {
        selected: "",
        monto: "$0,00",
        rate: 0,
        options: divisasKeys,
        comision: "",
        result: "$0,00",
      },
    ]);
    this.setOptionsOperations(divisasKeys);
    this.setRateData({
      eur: parseFloat(eur.replace(",", "."), 10),
      paypal: parseFloat(paypal.replace(",", "."), 10),
      usd: parseFloat(usd.replace(",", "."), 10),
      rate: parseFloat(rate.replace(",", "."), 10),
      rateUSD: Dinero({
        amount: parseInt(rateUSD.replace(/[,.$€\s]/g, ""), 10),
      }),
      divisasData,
      client: {
        rate: parseFloat(client.rate.replace(",", "."), 10),
        rateUSD: Dinero({
          amount: parseInt(client.rateUSD.replace(/[,.$€\s]/g, ""), 10),
        }),
      },
      comision,
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapMutations([
      "setOptionsOperations",
      "setRateData",
      "setArrayOperations",
    ]),
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    getRates,
    toUpperCase: (str) => str.charAt(0).toUpperCase() + str.slice(1),
  },
};
</script>

<style lang="scss" scoped>
hr.solid {
  border-top: 2px solid #999;
}
.calculator-container {
  background: #f7f7f8;
  border-radius: 20px;
  padding: 0 35px;
  font-size: "12px";
}
</style>
