<template>
  <!-- Start Footer Area -->
  <footer class="footer-area bg-f7fafd">
    <div class="container">
      <div class="row justify-content-center align-item-center">
        <div class="col-lg-2 col-sm-12">
          <div class="single-footer-widget">
            <div class="logo">
              <router-link to="/">
                <img src="../../assets/img/logo-rounded.svg" alt="logo" />
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12">
          <div class="single-footer-widget">
            <h3>{{ $t("footer.company") }}</h3>
            <ul class="list">
              <!-- <li>
                <router-link to="/">{{ $t("header.about-us") }}</router-link>
              </li> -->
              <li>
                <router-link to="/calculators">{{
                  $t("header.calculators")
                }}</router-link>
              </li>
              <li>
                <router-link to="/how-to-swap">{{
                  $t("header.how-to-swap")
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12">
          <div class="single-footer-widget">
            <h3>{{ $t("footer.contact-us") }}</h3>

            <ul class="footer-contact-info">
              <!-- <li>
                <feather type="map-pin"></feather> {{ $t("footer.address") }}
              </li> -->
              <li>
                <feather type="mail"></feather> {{ $t("footer.email-label") }}:
                <a :href="'mailto:' + $t('footer.email')">{{
                  $t("footer.email")
                }}</a>
              </li>
              <li>
                <feather type="phone-call"></feather>
                {{ $t("footer.phone-label") }}:
                <a :href="'tel:' + $t('footer.phone')">{{
                  $t("footer.phone")
                }}</a>
              </li>
            </ul>
            <ul class="social-links">
              <li>
                <a :href="'mailto:' + $t('footer.email')" class="mr-3"
                  ><feather type="mail"></feather
                ></a>
              </li>
              <li>
                <a :href="$t('footer.instagram')" class="mr-3"
                  ><feather type="instagram"></feather
                ></a>
              </li>
              <li>
                <a :href="$t('footer.twitter')"
                  ><feather type="twitter"></feather
                ></a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="copyright-area">
            <p>{{ $t("footer.note") }}</p>
            <p>Copyright &copy;2025 VEX. {{ $t("footer.copyright") }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- <img src="../../assets/img/map.png" class="map" alt="map" /> -->
    <!--
    <div class="shape1">
      <img src="../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../assets/img/shape2.svg" alt="shape" />
    </div>
    -->
    <!-- <div class="go-top"><feather type="arrow-up"></feather></div> -->

    <div v-if="width >= 1068" class="contact-icon">
      <div class="flex bubble">
        <a :href="whatsAppUrl" target="_blank">
          <span class="mr-2">{{ $t("footer.contact-us") }}</span>
          <img
            src="../../assets/img/akar-icons_whatsapp-fill.svg"
            alt="contact-us"
          />
        </a>
      </div>
    </div>

    <div v-else class="contact-icon-mobile">
      <div class="flex bubble">
        <a :href="whatsAppUrl" target="_blank">
          <img
            src="../../assets/img/akar-icons_whatsapp-fill.svg"
            alt="contact-us"
          />
        </a>
      </div>
    </div>

    <back-to-top bottom="50px" right="50px">
      <div class="go-top"><feather type="arrow-up"></feather></div>
    </back-to-top>
  </footer>
  <!-- End Footer Area -->
</template>

<script>
import BackToTop from "vue-backtotop";
export default {
  name: "Footer",
  components: { BackToTop },
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      whatsAppUrl: `https://wa.me/15513577165`,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style scoped lang="scss">
p {
  text-align: center;
}
.bubble {
  border: 1px solid #01c09e;
  border-radius: 10px 0 0 10px;
  padding: 0px;
  margin: 0px;
  line-height: 40px;
  background-color: #fff;
}
.contact-icon {
  position: fixed;
  cursor: pointer;
  bottom: 100px;
  right: 0;
  // color: #fff;
  // background-color: #3B8F76;
  z-index: 1;
  // display: none;
  width: 150px;
  text-align: center;
  height: 45px;
  border-radius: 10px 0 0 10px;
  line-height: 61px;
  transition: 0.5s;

  /* &:hover {
    color: #fff;
    background: #01C09E;
  } */
}
.contact-icon-mobile {
  position: fixed;
  cursor: pointer;
  bottom: 350px;
  right: 0;
  // color: #fff;
  // background-color: #3B8F76;
  z-index: 1;
  // display: none;
  width: 35px;
  text-align: center;
  height: 45px;
  border-radius: 10px 0 0 10px;
  line-height: 61px;
  transition: 0.5s;

  /* &:hover {
    color: #fff;
    background: #01C09E;
  } */
}
</style>